import "../FoundersMessage.css";
import CEO from "../images/nicoleYuen.png";
import FoundersMessagePdf from "../documents/huawei_transform.pdf";

import { useTranslation } from "react-i18next";

const FoundersMessage = () => {
  const { t } = useTranslation();

  return (
    <div className="founders-message">
      <div className="header-image-container">
        <div className="header-image">
          <p>{t("foundersMessage.title")}</p>
        </div>
      </div>
      <div className="content">
        <div className="md:grid md:grid-cols-6">
          <div className="md:col-span-2 m-auto">
            <img src={CEO} alt="Nicole Yuen" className="portrait" />
          </div>
          <div className="md:col-span-4">
            <div className="w-full">
              <div className="header">
                {t("foundersMessage.articleHKCGI.title")}
              </div>
              <div className="source">
                <span>{t("foundersMessage.articleHKCGI.source")}</span>
                <span> - {t("foundersMessage.articleHKCGI.date")}</span>
              </div>
              <div className="text">
                <p
                  dangerouslySetInnerHTML={{
                    __html: t("foundersMessage.articleHKCGI.abstract"),
                  }}
                ></p>
              </div>
              <div className="text-center">
                <a
                  href="https://cgj.hkcgi.org.hk/ultimate-revolution-humanity"
                  target="_blank"
                  className="primary-color-text"
                >
                  {t("readMore")}
                </a>
              </div>
            </div>
            <div className="w-full">
              <div className="header">
                {t("foundersMessage.articleHuawei.title")}
              </div>
              <div className="source">
                <span>{t("foundersMessage.articleHuawei.source")}</span>
                <span> - {t("foundersMessage.articleHuawei.date")}</span>
              </div>
              <div className="text">
                <p
                  dangerouslySetInnerHTML={{
                    __html: t("foundersMessage.articleHuawei.abstract"),
                  }}
                ></p>
              </div>
              <div className="text-center">
                <a
                  href={FoundersMessagePdf}
                  target="_blank"
                  className="primary-color-text"
                >
                  {t("readMore")}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FoundersMessage;

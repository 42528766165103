import React, { useState, useEffect } from 'react';
import ReactDOMServer from 'react-dom/server';
import axios from 'axios';
import "../CompanyProfileMobile.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faBullhorn } from '@fortawesome/free-solid-svg-icons';
import html2pdf from 'html2pdf.js/dist/html2pdf.min';
import DownloadIcon from '../images/download-icon-mobile.svg';
import goldBadge from '../images/gold_badge.png'
import basicBadge from '../images/basic_badge.png'
import CompanyProfilePdf from './CompanyProfilePdf';
import LoadingSpinner from './LoadingSpinner';

const CompanyProfileMobile = (props) => {
  const toggleSubText = (event) => {
    const parentDiv = event.target.parentElement.parentElement;
    const childrenArray = Array.from(parentDiv.children);
    const policyItems = childrenArray.filter((child) =>
      child.classList.value == 'company-policy-item-sub-text-mobile'
    );
    if (event.target.textContent[0] == '+'){
      setExpanded(true);
      policyItems.forEach((policyItem) => {
        policyItem.style.display = 'block';
      })
      event.target.textContent = '-' + event.target.textContent.substr(1);
    } else {
      setExpanded(false);
      policyItems.forEach((policyItem) => {
        policyItem.style.display = 'none';
      })
      event.target.textContent = '+' + event.target.textContent.substr(1);
    }
  };

  const expandAll = () => {
    const policyItems = document.querySelectorAll('.company-policy-item-sub-text-mobile');
    policyItems.forEach((policyItem) => {
      if (expanded) {
        const subHeader = policyItem.parentElement.children[0].children[0];
        subHeader.textContent = '+' + subHeader.textContent.substr(1);
        policyItem.style.display = 'none';
        setExpanded(false);
      } else {
        const subHeader = policyItem.parentElement.children[0].children[0];
        subHeader.textContent = '-' + subHeader.textContent.substr(1);
        policyItem.style.display = 'block';
        setExpanded(true);
      }
    })
  };

  const expandOne = (event) => {
    const buttonDiv = event.target;
    const parentDiv = event.target.parentElement.parentElement.parentElement.parentElement;
    const childrenArray = Array.from(parentDiv.children);
    childrenArray.forEach((policySection, i) => {
      if (i != 0) {
        const grandChildrenArray = Array.from(policySection.children).filter((child) =>
          child.classList.value == 'company-policy-item-sub-text-mobile'
        );
        if (buttonDiv.textContent == '+') {
          grandChildrenArray.forEach((policyItem) => {
            const subHeader = policyItem.parentElement.children[0].children[0];
            subHeader.textContent = '-' + subHeader.textContent.substr(1);
            policyItem.style.display = 'block';
          })
          setExpanded(true);
        }
        else {
          grandChildrenArray.forEach((policyItem) => {
            const subHeader = policyItem.parentElement.children[0].children[0];
            subHeader.textContent = '+' + subHeader.textContent.substr(1);
            policyItem.style.display = 'none';
          })
          setExpanded(false);
        }
      }
    })
    if (buttonDiv.textContent == '+') {
      buttonDiv.textContent = '-';
    } else {
      buttonDiv.textContent = '+';
    }
  }

  const {handleBackArrowClick} = props;
  const [companyData, setCompanyData] = useState();
  const [loading, setLoading] = useState(true);
  const [expanded, setExpanded] = useState(false);
  const [gold, setGold] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    };

    const requestData = {
      id: props.companyId,
    }

    axios.post('https://wwi-questionnaire-v2.azurewebsites.net/api/getCompanyData', requestData, config)
      .then(response => {
        if (response?.data?.data?.membership == 'GOLD') {
          setGold(true);
        }
        if (response?.data?.data?.membership !== 'GOLD' && response?.data?.data?.membership !== 'BASIC') {
          setShowModal(true);
        }
        setCompanyData(response.data);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error making POST request:', error);
        setLoading(false);
      });
  }, [props.companyId]);

  const checkLocation = (val) => {
    const locations = [
      { value: -1, label: "Asia Overall" },
      { value: 0, label: "Mainland China" },
      { value: 1, label: "Hong Kong SAR" },
      { value: 9, label: "Taiwan" },
      { value: 2, label: "India" },
      { value: 3, label: "Indonesia" },
      { value: 4, label: "Japan" },
      { value: 5, label: "Korea" },
      { value: 6, label: "Malaysia" },
      { value: 7, label: "the Philippines" },
      { value: 8, label: "Singapore" },
      { value: 10, label: "Thailand" },
      { value: 11, label: "Vietnam" },
    ];
  
    const locationObj = locations.find(location => location.value === val);
  
    return locationObj ? locationObj.label : "Unknown Location";
  } 

  const checkNaN = (val) => {
    if (isNaN(val)) {
      return 'NA';
    } else {
      return `${val}%`;
    }
  };

  const checkMinusOne = (val) => {
    return val === -1 ? 'N/A' : `${val}%`;
  }

  const calculatePercentage = (value1, value2) => {
    if ((value1 === 0 && value2 === 0) || (value1 === -1 && value2 === -1)) {
        return 'N/A';
    }
    return ((value1 / (value1 + value2)) * 100).toFixed(1) + '%';
  }

  const handleDownloadClick = () => {
    const printContent = <CompanyProfilePdf companyData={companyData} gold={gold} />;
    const printHtml = ReactDOMServer.renderToString(printContent);
    const container = document.createElement('div');
    container.innerHTML = printHtml;
  
    const options = {
      margin: [10, 0, 10, 0],
      filename: `${companyData?.data?.id}.pdf`,
      image: { type: 'jpeg', quality: 1 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
    };    
    html2pdf(container, options);
  };
  return loading ? <LoadingSpinner/> : (
    <>
      {showModal && 
        <div className='launching-modal-profile-mobile absolute'>
          <div className='launching-submodal-profile-mobile flex flex-col sm:flex-row my-10'>
            <div className='flex flex-row justify-between w-full'>
              <div className='flex justify-center align-center bg-[#760326]'>
                <FontAwesomeIcon icon={faBullhorn} className='h-[40px] w-[43px] m-1 text-white' onClick={handleBackArrowClick} />
              </div>
              <div className='my-2 mx-2'>
                <div className='launching-title-profile-mobile'>
                  Below inputs are collated by WWi based on the entity's public disclosure and feedback (if provided).
                </div>
              </div>
              <div>
                <div className='w-full' onClick={() => {setShowModal(false)}}>
                  <i className="fas fa-times text-[#760326] text-2xl cursor-pointer"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
      <div className="company-profile-mobile">
        <div className='button-header-mobile'>
          <FontAwesomeIcon icon={faArrowLeft} className='back-arrow-icon-mobile' onClick={handleBackArrowClick} />
          <img src={DownloadIcon} alt='download' className='download-arrow-icon-mobile' onClick={handleDownloadClick} ></img>
        </div>
        <div className='company-profile-header-section-mobile'>
          <div className='company-header-section1'>
            <div className='company-title-mobile'>{companyData?.data?.data?.['0.1']}</div>
            {/* <img src={VerifiedIcon} alt='back' className='verified-icon'></img> */}
            <div className='company-certification-mobile'>
              {
                (() => {
                  switch (companyData?.data?.membership) {
                    case "GOLD":
                      return <img src={goldBadge} alt="gold" className='w-[5rem]'/>
                    case "BASIC":
                      return <img src={basicBadge} alt="basic" className='w-[5rem]'/>
                    default:
                      return <div>IN-SCOPE</div>
                  }
                })()
              }
            </div>
          </div>
          <div className='company-header-section2'>
            <div className='company-header-subsection1-2-mobile'>
              Headquarter in Asia:&nbsp;&nbsp;<span style={{fontWeight:"400"}}>{companyData?.data?.data?.['0.7.b']}</span> <br/>
              Industry:&nbsp;&nbsp;<span style={{fontWeight:"400"}}>{companyData?.data?.data?.['0.5'][0]}&nbsp;&nbsp;&nbsp;|</span>&nbsp;&nbsp;&nbsp;<br/>Subindustry:&nbsp;&nbsp;&nbsp;<span style={{fontWeight:"400"}}>{companyData?.data?.data?.['0.5'][1]}</span>
            </div>
          </div>
          <div className='profile-line-mobile'/>
          <table className='company-header-section3'>
            <tbody>
              <tr className='info-titles-mobile'>
                <th className='nowrap pr-2'>About this entity:</th>
                <td className='info-details-mobile'>{companyData?.data?.data?.['0.2']}</td>
              </tr>
              <tr className='info-titles-mobile'>
                <th className='nowrap pr-2'><div className='info-titles-line'/></th>
                <td className='info-details-mobile'><div className='info-details-line'/></td>
              </tr>
              <tr className='info-titles-mobile'>
                <th className='nowrap pr-2'>Nature of entity:</th>
                <td className='info-details-mobile'>{companyData?.data?.data?.['0.4']} | {companyData?.data?.data?.['0.3.b'] || "N/A"}</td>
              </tr>
              <tr className='info-titles-mobile'>
                <th className='nowrap pr-2'><div className='info-titles-line'/></th>
                <td className='info-details-mobile'><div className='info-details-line'/></td>
              </tr>
              <tr className='info-titles-mobile'>
                <th className='nowrap pr-2'>Reporting Period:</th>
                <td className='info-details-mobile'>{companyData?.data?.data?.['0.6.1']} – {companyData?.data?.data?.['0.6.2']}</td>
              </tr>
              <tr className='info-titles-mobile'>
                <th className='nowrap pr-2'><div className='info-titles-line'/></th>
                <td className='info-details-mobile'><div className='info-details-line'/></td>
              </tr>
              <tr className='info-titles-mobile'>
                <th className='nowrap pr-2'>Employee Profile:</th>
                <td className='info-details-mobile'><span style={{fontWeight:"700"}}>Global:</span> {companyData?.data?.data?.['0.8.1'] != '-1' ? `${companyData?.data?.data?.['0.8.1']} employees ` : "Not disclosed"} | <span style={{fontWeight:"700"}}>Asia:</span> {companyData?.data?.data?.['0.8.2'] != '-1' ? `${companyData?.data?.data?.['0.8.2']} employees ` : "Not disclosed"}</td>
              </tr>
              <tr className='info-titles-mobile'>
                <th className='nowrap pr-2'><div className='info-titles-line'/></th>
                <td className='info-details-mobile'><div className='info-details-line'/></td>
              </tr>
              <tr className='info-titles-mobile'>
              <th className='nowrap pr-2'>Public Disclosures:</th>
              {companyData?.data?.data?.['18.1'] == 'Public disclosures are available' ? (
                <td className='info-details-mobile'>{companyData?.data?.data?.['18.1.a']?.map((item) => (
                  <>
                    <a href={item?.['18.1.a.2']}>{item?.['18.1.a.1']}</a>
                    <span>&nbsp;|&nbsp;</span>
                  </>
                ))}</td>                    
              ):(
                <td className='info-details-mobile'>N/A</td>
              )}
            </tr>
            <tr className='info-titles-mobile'>
              <th className='nowrap pr-2'><div className='info-titles-line'/></th>
              <td className='info-details-mobile'><div className='info-details-line'/></td>
            </tr>
            <tr className='info-titles-mobile'>
              <th className='nowrap pr-2'>Public Policies:</th>
              {companyData?.data?.data?.['18.2'] == 'Public disclosures are available' ? (
                <td className='info-details-mobile'>{companyData?.data?.data?.['18.2.a']?.map((item) => (
                  <>
                    <a href={item?.['18.2.a.2']}>{item?.['18.2.a.1']}</a>
                    <span>&nbsp;|&nbsp;</span>
                  </>
                ))}</td>                    
              ):(
                <td className='info-details-mobile'>N/A</td>
              )}
            </tr>
            <tr className='info-titles-mobile'>
              <th className='nowrap pr-2'><div className='info-titles-line'/></th>
              <td className='info-details-mobile'><div className='info-details-line'/></td>
            </tr>
            </tbody>
          </table>
          <div className='profile-line-mobile'/>
        </div>
        <div className='expand-all-div-mobile flex justify-center'>
          <div className='red-button-thicker-mobile'>
            <p className='red-button-thicker-text-mobile' onClick={() => expandAll()}>{expanded ? "Collapse All" : "Expand All"}</p>
          </div>
        </div>

        <div className='company-policy-mobile'>
          <div className='company-policy-header'>
            <div className='company-policy-header-line1'>
              <div className='company-policy-title-mobile'>Policy and Operations</div>
              <div className='plus-sign-btn-mobile' onClick={(event) => expandOne(event)}><p className='plus-sign-mobile'>{expanded ? "-" : "+"}</p></div>
            </div>
            <div className='company-policy-text-mobile'>On various key woman topics, does the entity have established, open and well communicated policy integrated in its operating system?</div>
          </div>
          <div className='company-policy-section'>
            <div className='company-policy-items-mobile'>
              <div className='company-policy-item-text-mobile' onClick={(event) => toggleSubText(event)}>+ Management responsibility</div>
              {/* <div className='company-policy-item-star'>★ Star Practice</div> */}
            </div>
            <div className='company-policy-item-sub-text-mobile'>
              1. Has a senior management-level position or committee in the entity in Asia with responsibility for gender diversity issues<br/><br/>
              <span style={{fontWeight:"600"}}>{companyData?.data?.data?.['1.1']} {(companyData?.data?.data?.['1.1.a'] && companyData?.data?.data?.['1.1.a']?.[0] != 'N') ? `- ${companyData?.data?.data?.['1.1.a']}` : ''}</span>
              {gold && <span style={{fontWeight:"600"}}><br/>{companyData?.data?.data?.['1.1.b']}</span>}
              <br/><br/>
            </div>
          </div>

          <div className='company-policy-section'>
            <div className='company-policy-items-mobile'>
              <div className='company-policy-item-text-mobile' onClick={(event) => toggleSubText(event)}>+ Anti-sexual harrasment</div>
            </div>
            <div className='company-policy-item-sub-text-mobile'>
              <span>2.1 Has policy on against sexual harassment behaviour that is made known to all employees </span><br/><br/>
              <span className='company-policy-item-ans-section-mobile'>{companyData?.data?.data?.['2.1']}</span>
              {gold && <span style={{marginLeft:"16px",fontWeight:"600"}}><br/>{companyData?.data?.data?.['2.1.a']}</span>}
                  {gold && <span style={{fontWeight:"600"}}><br/>{companyData?.data?.data?.['2.1.b']}</span>}
                  {gold && <span style={{fontWeight:"600"}}><br/>{companyData?.data?.data?.['2.1.c']}</span>}
                  
              <br/><br/>
            </div>
            <div className='company-policy-item-sub-text-mobile'>
              2.2 Provides channels for both employees and external stakeholders to report sexual harassment in / outside the physical workplace<br/><br/>
              <span style={{fontWeight:"600"}}>{companyData?.data?.data?.['2.2']}</span>
              {gold && <span style={{fontWeight:"600"}}><br/>{companyData?.data?.data?.['2.2.a']}</span>}
              {gold && <span style={{fontWeight:"600"}}><br/>{companyData?.data?.data?.['2.2.b']}</span>}   
              <br/><br/>
            </div>
            <div className='company-policy-item-sub-text-mobile'>
              2.3 Has mechanism that ensures the independence of internal reporting and investigation on sexual harassment allegations <br/><br/>
              <span style={{fontWeight:"600"}}>{companyData?.data?.data?.['2.3']}</span>
              {gold && <span style={{marginLeft:"16px",fontWeight:"600"}}><br/>{companyData?.data?.data?.['2.3.a']}</span>}
                  {gold && <span style={{marginLeft:"16px",fontWeight:"600"}}><br/>{companyData?.data?.data?.['2.3.b']}</span>}
                  
              <br/><br/>
            </div>
          </div>

          <div className='company-policy-section'>
            <div className='company-policy-items-mobile'>
              <div className='company-policy-item-text-mobile' onClick={(event) => toggleSubText(event)}>+ Non-discrimination</div>
            </div>
            <div className='company-policy-item-sub-text-mobile'>
              <span>3.1 Has (i) policy on anti-discrimination based on gender that is made known to all employees, and (ii) with specific guidelines on how it is defined and implemented in the HR management process</span><br/><br/>
              <span className='company-policy-item-ans-section-mobile'>{companyData?.data?.data?.['3.1']}</span>
              <br/><br/>
            </div>
            <div className='company-policy-item-sub-text-mobile'>
              3.2 Provides channels for both employees and external stakeholders to report discrimination in / outside the physical workplace<br/><br/>
              <span style={{fontWeight:"600"}}>{companyData?.data?.data?.['3.2']}</span>
              {gold && <span style={{fontWeight:"600"}}><br/>{companyData?.data?.data?.['3.2.a']}</span>}
              {gold && <span style={{fontWeight:"600"}}><br/>{companyData?.data?.data?.['3.2.b']}</span>}                  
              <br/><br/>
            </div>
            <div className='company-policy-item-sub-text-mobile'>
              3.3 Has mechanism that ensures the independence of internal reporting and investigation on discrimination allegations <br/><br/>
              <span style={{fontWeight:"600"}}>{companyData?.data?.data?.['3.3']}</span>
              {gold && <span style={{fontWeight:"600"}}><br/>{companyData?.data?.data?.['3.3.a']}</span>}
              {gold && <span style={{fontWeight:"600"}}><br/>{companyData?.data?.data?.['3.3.b']}</span>}
              <br/><br/>
            </div>
          </div>

          <div className='company-policy-section'>
            <div className='company-policy-items-mobile'>
              <div className='company-policy-item-text-mobile' onClick={(event) => toggleSubText(event)}>+ Parental leave</div>
            </div>
            <div className='company-policy-item-sub-text-mobile'>
              <span>4.1 Provides parental leave benefits beyond applicable statutory requirements</span><br/><br/>
              {companyData?.data?.data?.['4.1'].toString().includes('Yes') ? (
                <>
                  <span className='company-policy-item-ans-section-mobile'>{companyData?.data?.data?.['4.1']}</span><br/>
                  {!gold && companyData?.data?.data?.['4.1.a']?.map((item) => (
                    <>
                      {item?.['4.1.a.1'] !== -1 && <span><span className='company-policy-item-ans-section-mobile'>{checkLocation(item?.['4.1.a.loc'])} - Full paid - Maternity leave (Primary carer): {`${item?.['4.1.a.1']} days`}, {companyData?.data?.data?.['4.1.a']?.[0]?.['4.1.a.3']}</span><br/></span>}
                      {item?.['4.1.a.2'] !== -1 && <span><span className='company-policy-item-ans-section-mobile'>{checkLocation(item?.['4.1.a.loc'])} - Full paid - Paternity leave (Secondary carer): {`${item?.['4.1.a.2']} days`}, {companyData?.data?.data?.['4.1.a']?.[0]?.['4.1.a.3']}</span><br/></span>}
                      {item?.['4.1.a.7'] !== -1 && <span><span className='company-policy-item-ans-section-mobile'>{checkLocation(item?.['4.1.a.loc'])} - Part paid - Maternity leave (Primary carer): % {`${item?.['4.1.a.7']} Pay`}, {item?.['4.1.a.4'] === -1 ? 'Days not disclosed' : `${item?.['4.1.a.4']} Days`}, {companyData?.data?.data?.['4.1.a']?.[0]?.['4.1.a.6']}</span><br/></span>}
                      {item?.['4.1.a.8'] !== -1 && <span><span className='company-policy-item-ans-section-mobile'>{checkLocation(item?.['4.1.a.loc'])} - Part paid - Paternity leave (Secondary carer): % {`${item?.['4.1.a.8']} Pay`}, {item?.['4.1.a.5'] === -1 ? 'Days not disclosed' : `${item?.['4.1.a.5']} Days`}, {companyData?.data?.data?.['4.1.a']?.[0]?.['4.1.a.6']}</span><br/></span>}
                      {item?.['4.1.a.9'] !== -1 && <span><span className='company-policy-item-ans-section-mobile'>{checkLocation(item?.['4.1.a.loc'])} - Full paid - Maternity leave (Primary carer): {`${item?.['4.1.a.9']}`}, {companyData?.data?.data?.['4.1.a']?.[0]?.['4.1.a.11']}</span><br/></span>}
                      {item?.['4.1.a.10'] !== -1 && <span><span className='company-policy-item-ans-section-mobile'>{checkLocation(item?.['4.1.a.loc'])} - Full paid - Maternity leave (Primary carer): {`${item?.['4.1.a.10']}`}, {companyData?.data?.data?.['4.1.a']?.[0]?.['4.1.a.11']}</span><br/></span>}
                    </>
                  ))}
                  {gold && companyData?.data?.data?.['4.1.a']?.map((item) => (
                    <>
                      {item?.['4.1.a.1'] !== -1 && <span><span className='company-policy-item-ans-section-mobile'>{checkLocation(item?.['4.1.a.loc'])} - Full paid - Maternity leave (Primary carer): {`${item?.['4.1.a.1']} days`}, {companyData?.data?.data?.['4.1.a']?.[0]?.['4.1.a.3']}</span><br/></span>}
                      {item?.['4.1.a.2'] !== -1 && <span><span className='company-policy-item-ans-section-mobile'>{checkLocation(item?.['4.1.a.loc'])} - Full paid - Paternity leave (Secondary carer): {`${item?.['4.1.a.2']} days`}, {companyData?.data?.data?.['4.1.a']?.[0]?.['4.1.a.3']}</span><br/></span>}
                      {item?.['4.1.a.7'] !== -1 && <span><span className='company-policy-item-ans-section-mobile'>{checkLocation(item?.['4.1.a.loc'])} - Part paid - Maternity leave (Primary carer): % {`${item?.['4.1.a.7']} Pay`}, {item?.['4.1.a.4'] === -1 ? 'Days not disclosed' : `${item?.['4.1.a.4']} Days`}, {companyData?.data?.data?.['4.1.a']?.[0]?.['4.1.a.6']}</span><br/></span>}
                      {item?.['4.1.a.8'] !== -1 && <span><span className='company-policy-item-ans-section-mobile'>{checkLocation(item?.['4.1.a.loc'])} - Part paid - Paternity leave (Secondary carer): % {`${item?.['4.1.a.8']} Pay`}, {item?.['4.1.a.5'] === -1 ? 'Days not disclosed' : `${item?.['4.1.a.5']} Days`}, {companyData?.data?.data?.['4.1.a']?.[0]?.['4.1.a.6']}</span><br/></span>}
                      {item?.['4.1.a.9'] !== -1 && <span><span className='company-policy-item-ans-section-mobile'>{checkLocation(item?.['4.1.a.loc'])} - Full paid - Maternity leave (Primary carer): {`${item?.['4.1.a.9']}`}, {companyData?.data?.data?.['4.1.a']?.[0]?.['4.1.a.11']}</span><br/></span>}
                      {item?.['4.1.a.10'] !== -1 && <span><span className='company-policy-item-ans-section-mobile'>{checkLocation(item?.['4.1.a.loc'])} - Full paid - Maternity leave (Primary carer): {`${item?.['4.1.a.10']}`}, {companyData?.data?.data?.['4.1.a']?.[0]?.['4.1.a.11']}</span><br/></span>}
                    </>
                  ))}
                </>
              ) : (
                <span className='company-policy-item-ans-section-mobile'>{companyData?.data?.data?.['4.1']}<br/></span>
              )}
              <br/><br/>
            </div>
          </div>

          <div className='company-policy-section'>
            <div className='company-policy-items-mobile'>
              <div className='company-policy-item-text-mobile' onClick={(event) => toggleSubText(event)}>+ Return to work</div>
            </div>
            <div className='company-policy-item-sub-text-mobile'>
              <span>5.1 Tracks the retention rate of women employees who return to work from maternity leave and remain employed for 12 months after their leaves </span><br/><br/>
              <span className='company-policy-item-ans-section-mobile'>{companyData?.data?.data?.['5.1']}</span>
              {companyData?.data?.data?.['5.1.a'] && <span className='company-policy-item-ans-section-mobile'>{companyData?.data?.data?.['5.1.a']}%</span>}
              <br/><br/>
            </div>
            {gold && 
              <>
                <div className='company-policy-item-sub-text-mobile'>
                  5.2 Measures to recruit and/or support women returning to the workforce after maternity leaves or a career break to take care of their family <br/><br/>
                  <span style={{fontWeight:"600"}}>{companyData?.data?.data?.['5.2']}</span>
                  <br/><br/>
                  <span style={{fontWeight:"600"}}>{companyData?.data?.data?.['5.2.a']}</span>
                  <br/><br/>
                  <span style={{fontWeight:"600"}}>{companyData?.data?.data?.['5.2.b']}</span>
                  <br/><br/>
                </div>
              </>
            }
          </div>

          <div className='company-policy-section'>
            <div className='company-policy-items-mobile'>
              <div className='company-policy-item-text-mobile' onClick={(event) => toggleSubText(event)}>+ Family care</div>
            </div>
            <div className='company-policy-item-sub-text-mobile'>
              6.1 Provides any services/ facilities/ supports that help women employees alleviate their responsibilities in relation to taking care of their family<br/><br/>
              <span className='company-policy-item-ans-section-mobile'>{companyData?.data?.data?.['6.1']}</span>
              {companyData?.data?.data?.['6.1.a'] && (
                <>
                  {companyData.data.data['6.1.a'].map((item, index) => (
                    <span key={index} style={{ fontWeight: "600", marginTop: "10px"}}>{item}<br/></span>
                  ))}
                </>
              )}
              <span className='company-policy-item-ans-section-mobile'>{companyData?.data?.data?.['6.1.a.1']}</span>
              <br/><br/>
            </div>
          </div>

          <div className='company-policy-section'>
            <div className='company-policy-items-mobile'>
              <div className='company-policy-item-text-mobile' onClick={(event) => toggleSubText(event)}>+ Pay</div>
            </div>
            <div className='company-policy-item-sub-text-mobile'>
              7.1 Tracks any gender pay metrics to review the entity’s performance in ensuring equal pay (i.e., male and female employees are paid equally for equivalent types of work, positions and experience<br/><br/>
              <span className='company-policy-item-ans-section-mobile'>{companyData?.data?.data?.['7.1']}</span>
              {gold ? 
                <span className='company-policy-item-ans-section-mobile'>{companyData?.data?.data?.['7.1.a'] ? `${companyData?.data?.data?.['7.1.a']}%` : 'Disclosure not identified by WWI'}</span>
              :
                <span className='company-policy-item-ans-section-mobile'>{companyData?.data?.data?.['7.1.a'] ? `Percentage(%) of earnings for female employees compared to male employees: ${companyData?.data?.data?.['7.1.a']}` : ''}</span>
              }
              <br/><br/>
            </div>
            {gold && 
              <>
                <div className='company-policy-item-sub-text-mobile'>
                  7.2 Track any gender pay metrics to review the entity’s performance in ensuring equal pay<br/><br/>
                  <span className='company-policy-item-ans-section-mobile'>{companyData?.data?.data?.['7.2']}</span><br/>
                  <span className='company-policy-item-ans-section-mobile'>{checkLocation(companyData?.data?.data?.['7.2.a']?.[0]?.['7.2.a.loc'])} - {companyData?.data?.data?.['7.2.a']?.[0]?.['7.2.a.1']} </span>
                  {companyData?.data?.data?.['7.2.a']?.[0]?.['7.2.a.2'] && 
                    <span className='company-policy-item-ans-section-mobile'><br/>{checkLocation(companyData?.data?.data?.['7.2.a']?.[0]?.['7.2.a.loc'])} - {companyData?.data?.data?.['7.2.a']?.[0]?.['7.2.a.2']} </span> 
                  }
                  {companyData?.data?.data?.['7.2.a']?.[0]?.['7.2.a.3'] && 
                    <span className='company-policy-item-ans-section-mobile'><br/>{checkLocation(companyData?.data?.data?.['7.2.a']?.[0]?.['7.2.a.loc'])} - {companyData?.data?.data?.['7.2.a']?.[0]?.['7.2.a.3']} </span> 
                  }
                  {companyData?.data?.data?.['7.2.b']?.[0]?.['7.2.b.2'] && 
                    <span className='company-policy-item-ans-section-mobile'><br/>{checkLocation(companyData?.data?.data?.['7.2.b']?.[0]?.['7.2.b.loc'])} - Percentage(%) of earnings for female employees compared to male employees: {companyData?.data?.data?.['7.2.b']?.[0]?.['7.2.b.2']}% </span> 
                  }
                  {companyData?.data?.data?.['7.2.b']?.[0]?.['7.2.b.3'] && 
                    <span className='company-policy-item-ans-section-mobile'><br/>Remarks: {companyData?.data?.data?.['7.2.b']?.[0]?.['7.2.b.3']} </span> 
                  }
                  {companyData?.data?.data?.['7.2.b']?.[0]?.['7.2.b.4'] && companyData?.data?.data?.['7.2.b']?.[0]?.['7.2.b.1'] == "We have the gender pay gap data but do not wish to provide it at the moment" &&
                    <span className='company-policy-item-ans-section-mobile'><br/>{companyData?.data?.data?.['7.2.b']?.[0]?.['7.2.b.4']} </span> 
                  }
                  {companyData?.data?.data?.['7.2.c']?.[0]?.['7.2.c.loc'] && companyData?.data?.data?.['7.2.c']?.[0]?.['7.2.c.1'] && companyData?.data?.data?.['7.2.c']?.[0]?.['7.2.c.2'] && 
                    <span className='company-policy-item-ans-section-mobile'><br/>{checkLocation(companyData?.data?.data?.['7.2.c']?.[0]?.['7.2.c.loc'])} - {companyData?.data?.data?.['7.2.c']?.[0]?.['7.2.c.1']} <br/>{companyData?.data?.data?.['7.2.c']?.[0]?.['7.2.c.2']} </span> 
                  }
                  {companyData?.data?.data?.['7.2.d'] && companyData?.data?.data?.['7.2'] == "Yes – We track other gender pay metrics" &&
                    <span className='company-policy-item-ans-section-mobile'><br/>{companyData?.data?.data?.['7.2.d']} </span> 
                  }
                  {companyData?.data?.data?.['7.2.e'] && companyData?.data?.data?.['7.2'] == "No – We currently do not track such information" &&
                    <span className='company-policy-item-ans-section-mobile'><br/>{companyData?.data?.data?.['7.2.e']} </span> 
                  }
                  <br/><br/>
                </div>
                <div className='company-policy-item-sub-text-mobile'>
                  7.3 Takes rectification action if a gender pay difference is identified<br/><br/>
                  {companyData?.data?.data?.['7.3'] == "Yes" &&
                    <span className='company-policy-item-ans-section-mobile'>{companyData?.data?.data?.['7.3.a']}<br/></span>
                  }
                  <br/><br/>
                  {companyData?.data?.data?.['7.3'] == "No – No rectification action is in place currently" &&
                    <span className='company-policy-item-ans-section-mobile'>{companyData?.data?.data?.['7.3.b']}<br/></span>
                  }
                  <br/><br/>
                </div>
              </>
            }
          </div>
        </div>

        <div className='company-policy-mobile'>
          <div className='company-policy-header'>
            <div className='company-policy-header-line1'>
              <div className='company-policy-title-mobile'>People and Culture</div>
              <div className='plus-sign-btn-mobile' onClick={(event) => expandOne(event)}><p className='plus-sign-mobile'>{expanded ? "-" : "+"}</p></div>
            </div>
            <div className='company-policy-text-mobile'>On various key woman topics, does the entity monitor performance and dedicate resources to improve diversity and inclusivity ?</div>
          </div>
          <div className='company-policy-section'>
            <div className='company-policy-items-mobile'>
              <div className='company-policy-item-text-mobile' onClick={(event) => toggleSubText(event)}>+ Workforce gender diversity</div>
              {/* <div className='company-policy-item-star'>★ Star Practice</div> */}
            </div>
            <div className='company-policy-item-sub-text-mobile'>
              8.1 Track percentage of women in the workforce (excluding Board of Directors) <br/><br/>
              {companyData?.data?.data?.['8.1']?.map((item) => (
                <>
                  <span className='company-policy-item-ans-section-mobile'>{checkLocation(item?.['8.1.loc'])}: {calculatePercentage(parseFloat(item?.['8.1.2']), parseFloat(item?.['8.1.1']))} </span> 
                  {(item?.['8.1.4'] && item?.['8.1.3']) ? (
                    <span>
                      {"("}previous year: {calculatePercentage(parseFloat(item?.['8.1.4']), parseFloat(item?.['8.1.3']))}
                      {(item?.['8.1.5'] && item?.['8.1.6'] && item?.['8.1.5'] != -1 && item?.['8.1.6'] != -1) ? (
                        <span>
                          , the year before the previous year: {calculatePercentage(parseFloat(item?.['8.1.6']), parseFloat(item?.['8.1.5']))}
                        </span>
                      ) : <span>, the year before the previous year: N/A</span>}
                      {")"}
                    </span>
                  ) : ''}
                  <br/>
                </>
              ))}
              <br/>
            </div>
            <div className='company-policy-item-sub-text-mobile'>
            8.2 Quantitative target to increase / ensure female employee propotion <br/><br/>
            {!gold && 
              <span className='company-policy-item-ans-section-mobile'>{companyData?.data?.data?.['8.2']}<br/>{companyData?.data?.data?.['8.2.a']}<br/>{companyData?.data?.data?.['8.2.c']}<br/><br/></span>
            }
            {gold && 
              <>
                <span style={{fontWeight:"600"}}>
                  {checkLocation(companyData?.data?.data?.['8.2']?.[0]?.['8.2.loc'])}
                  - Senior management (including C-suite and other senior management): &nbsp;
                  {(companyData?.data?.data?.['8.2']?.[0]?.['8.2.2'] / (companyData?.data?.data?.['8.2']?.[0]?.['8.2.1'] + companyData?.data?.data?.['8.2']?.[0]?.['8.2.2']) * 100).toFixed(1)}%
                  (previous year: {checkNaN((companyData?.data?.data?.['8.2']?.[0]?.['8.2.4'] / (companyData?.data?.data?.['8.2']?.[0]?.['8.2.3'] + companyData?.data?.data?.['8.2']?.[0]?.['8.2.4']) * 100).toFixed(1))}
                  , the year before the previous year: {checkNaN((companyData?.data?.data?.['8.2']?.[0]?.['8.2.6'] / (companyData?.data?.data?.['8.2']?.[0]?.['8.2.5'] + companyData?.data?.data?.['8.2']?.[0]?.['8.2.6']) * 100).toFixed(1))} )
                </span>
                <br/>
                <span style={{fontWeight:"600"}}>
                  {checkLocation(companyData?.data?.data?.['8.2']?.[0]?.['8.2.loc'])}
                  - Middle and other management: &nbsp;
                  {(companyData?.data?.data?.['8.2']?.[0]?.['8.2.8'] / (companyData?.data?.data?.['8.2']?.[0]?.['8.2.7'] + companyData?.data?.data?.['8.2']?.[0]?.['8.2.8']) * 100).toFixed(1)}%
                  (previous year: {checkNaN((companyData?.data?.data?.['8.2']?.[0]?.['8.2.10'] / (companyData?.data?.data?.['8.2']?.[0]?.['8.2.9'] + companyData?.data?.data?.['8.2']?.[0]?.['8.2.10']) * 100).toFixed(1))}
                  , the year before the previous year: {checkNaN((companyData?.data?.data?.['8.2']?.[0]?.['8.2.12'] / (companyData?.data?.data?.['8.2']?.[0]?.['8.2.11'] + companyData?.data?.data?.['8.2']?.[0]?.['8.2.12']) * 100).toFixed(1))} )
                </span>
                <br/>
                <span style={{fontWeight:"600"}}>
                  {checkLocation(companyData?.data?.data?.['8.2']?.[0]?.['8.2.loc'])}
                  - Middle and other management: &nbsp;
                  {(companyData?.data?.data?.['8.2']?.[0]?.['8.2.14'] / (companyData?.data?.data?.['8.2']?.[0]?.['8.2.13'] + companyData?.data?.data?.['8.2']?.[0]?.['8.2.14']) * 100).toFixed(1)}%
                  (previous year: {checkNaN((companyData?.data?.data?.['8.2']?.[0]?.['8.2.16'] / (companyData?.data?.data?.['8.2']?.[0]?.['8.2.15'] + companyData?.data?.data?.['8.2']?.[0]?.['8.2.16']) * 100).toFixed(1))}
                  , the year before the previous year: {checkNaN((companyData?.data?.data?.['8.2']?.[0]?.['8.2.18'] / (companyData?.data?.data?.['8.2']?.[0]?.['8.2.17'] + companyData?.data?.data?.['8.2']?.[0]?.['8.2.18']) * 100).toFixed(1))} )
                </span>
                <br/><br/>
              </>
            }
          </div>

            {gold && 
              <div className='company-policy-item-sub-text-mobile'>
                8.3 Proportion of total employee workforce comprising women, categorised by function  <br/><br/>
                <span style={{fontWeight:"600"}}>
                  {checkLocation(companyData?.data?.data?.['8.3']?.[0]?.['8.3.loc'])}
                  - Asia Overall - Revenue-generating functions: &nbsp;
                  {checkNaN((companyData?.data?.data?.['8.3']?.[0]?.['8.3.2'] / (companyData?.data?.data?.['8.3']?.[0]?.['8.3.1'] + companyData?.data?.data?.['8.3']?.[0]?.['8.3.2']) * 100).toFixed(1))}
                </span>
                <br/>
                <br/><br/>
              </div>
            }

            {gold && 
              <div className='company-policy-item-sub-text-mobile'>
                8.4 Quantitative target to increase/ensure female employee proportion  <br/><br/>
                <span style={{fontWeight:"600"}}>
                  {companyData?.data?.data?.['8.4'] == 'Yes – Please provide details below as applicable' ? 
                    `Yes - ${companyData?.data?.data?.['8.4.a']}`
                    :
                    companyData?.data?.data?.['8.4'] == 'No – We believe there is currently no need to set a target' ?
                    "No"
                    :
                    `${companyData?.data?.data?.['8.4.a']}`
                  }
                </span>
                <br/><br/>
              </div>
            }
          </div>

          <div className='company-policy-section'>
            <div className='company-policy-items-mobile'>
              <div className='company-policy-item-text-mobile' onClick={(event) => toggleSubText(event)}>+ Women leadership</div>
            </div>
            {gold ? 
              <div className='company-policy-item-sub-text-mobile'>
                9.1 Percentage of women in Board  <br/><br/>
                <span style={{fontWeight:"600"}}>
                  {companyData?.data?.data?.['9.1'] == 'Yes – We track data on our Board of Directors composition' ?
                  <span>Board: 8.33%  (previous year: 0% the year before previous year: 0%), comprising 
                    <br/> - Chairperson: {checkNaN((companyData?.data?.data?.['9.1.a.2'] / (companyData?.data?.data?.['9.1.a.1'] + companyData?.data?.data?.['9.1.a.2']) * 100).toFixed(1))}
                    <br/> - Executive Directors: {checkNaN((companyData?.data?.data?.['9.1.a.8'] / (companyData?.data?.data?.['9.1.a.7'] + companyData?.data?.data?.['9.1.a.8']) * 100).toFixed(1))}
                    <br/> - Non-executive directors: {checkNaN((companyData?.data?.data?.['9.1.a.14'] / (companyData?.data?.data?.['9.1.a.13'] + companyData?.data?.data?.['9.1.a.14']) * 100).toFixed(1))}
                    <br/> - Independent non-executive directors {checkNaN((companyData?.data?.data?.['9.1.a.20'] / (companyData?.data?.data?.['9.1.a.19'] + companyData?.data?.data?.['9.1.a.20']) * 100).toFixed(1))}
                  </span>
                  :
                  companyData?.data?.data?.['9.1']
                  }
                </span>
                <br/><br/>
              </div>
            :
              <div className='company-policy-item-sub-text-mobile'>
                9.1 Percentage of women in Board and C-suite management <br/><br/>
                <span style={{fontWeight:"600"}}>Board: {checkMinusOne(companyData?.data?.data?.['9.1.1'])}, C-suite: {checkMinusOne(companyData?.data?.data?.['9.1.2'])}</span>
                <br/><br/>
              </div>
            }
            {gold && 
              <div className='company-policy-item-sub-text-mobile'>
                9.2 Percentage of women in C-Suite <br/><br/>
                <span style={{fontWeight:"600"}}>
                  {companyData?.data?.data?.['9.2'] == 'Yes – We track data on our C-suite composition' ?
                  <span>CEO: {checkNaN((companyData?.data?.data?.['9.2.a.2'] / (companyData?.data?.data?.['9.2.a.1'] + companyData?.data?.data?.['9.2.a.2']) * 100).toFixed(1))}
                    {" ("}previous year: {checkNaN((companyData?.data?.data?.['9.2.a.4'] / (companyData?.data?.data?.['9.2.a.3'] + companyData?.data?.data?.['9.2.a.4']) * 100).toFixed(1))},
                    the year before previous year: {checkNaN((companyData?.data?.data?.['9.2.a.6'] / (companyData?.data?.data?.['9.2.a.5'] + companyData?.data?.data?.['9.2.a.6']) * 100).toFixed(1))}{")"},
                    Other C-suite: {checkNaN((companyData?.data?.data?.['9.2.a.8'] / (companyData?.data?.data?.['9.2.a.7'] + companyData?.data?.data?.['9.2.a.8']) * 100).toFixed(1))}
                    {" ("}previous year: {checkNaN((companyData?.data?.data?.['9.2.a.10'] / (companyData?.data?.data?.['9.2.a.9'] + companyData?.data?.data?.['9.2.a.10']) * 100).toFixed(1))},
                      the year before previous year: {checkNaN((companyData?.data?.data?.['9.2.a.12'] / (companyData?.data?.data?.['9.2.a.11'] + companyData?.data?.data?.['9.2.a.12']) * 100).toFixed(1))}{")"}
                  </span>
                  :
                  companyData?.data?.data?.['9.1']
                  }
                </span>
                <br/><br/>
              </div>
            }
          </div>

          <div className='company-policy-section'>
            <div className='company-policy-items-mobile'>
              <div className='company-policy-item-text-mobile' onClick={(event) => toggleSubText(event)}>+ Recruitment and promotion</div>
            </div>
            {gold ? 
              <>
                <div className='company-policy-item-sub-text-mobile'>
                  10.1 Tracks the proportion of women in total new employee hiring and promotion during the current reporting year, categorised by level <br/><br/>
                  <span className='company-policy-item-ans-section-mobile'>{(companyData?.data?.data?.['10.1'])}</span><br/>
                  {companyData?.data?.data?.['10.1'] == 'Yes – We track data in this regard' && 
                    <span>
                      - Total workforce: {checkNaN((companyData?.data?.data?.['10.1.a.2'] / (companyData?.data?.data?.['10.1.a.1'] + companyData?.data?.data?.['10.1.a.2']) * 100).toFixed(1))}
                        new hires, {checkNaN((companyData?.data?.data?.['10.1.a.4'] / (companyData?.data?.data?.['10.1.a.3'] + companyData?.data?.data?.['10.1.a.4']) * 100).toFixed(1))}
                        promotions <br/>
                      - Senior management (including C-suite and senior management): 
                        {checkNaN((companyData?.data?.data?.['10.1.a.6'] / (companyData?.data?.data?.['10.1.a.5'] + companyData?.data?.data?.['10.1.a.6']) * 100).toFixed(1))}
                        new hires, 
                        {checkNaN((companyData?.data?.data?.['10.1.a.8'] / (companyData?.data?.data?.['10.1.a.7'] + companyData?.data?.data?.['10.1.a.8']) * 100).toFixed(1))} promotions<br/>
                      - Middle and other management: {checkNaN((companyData?.data?.data?.['10.1.a.10'] / (companyData?.data?.data?.['10.1.a.9'] + companyData?.data?.data?.['10.1.a.10']) * 100).toFixed(1))}
                        new hires, {checkNaN((companyData?.data?.data?.['10.1.a.12'] / (companyData?.data?.data?.['10.1.a.11'] + companyData?.data?.data?.['10.1.a.12']) * 100).toFixed(1))}
                        promotions<br/>
                      - Non-managerial staff: {checkNaN((companyData?.data?.data?.['10.1.a.14'] / (companyData?.data?.data?.['10.1.a.13'] + companyData?.data?.data?.['10.1.a.14']) * 100).toFixed(1))}
                        new hires, {checkNaN((companyData?.data?.data?.['10.1.a.16'] / (companyData?.data?.data?.['10.1.a.15'] + companyData?.data?.data?.['10.1.a.16']) * 100).toFixed(1))} promotions
                    </span>
                  }
                  <span style={{marginLeft:"16px",fontWeight:"600",marginTop:"60px"}}>{(companyData?.data?.data?.['10.1.c'])}</span><br/>
                  <br/>
                </div>
                <div className='company-policy-item-sub-text-mobile'>
                  10.2 Quantitative target for women recruitment and/or promotion in total workforce and/or specific employee classification by level or function<br/><br/>
                  <span className='company-policy-item-ans-section-mobile'>
                    {(companyData?.data?.data?.['10.2'] == 'Yes – Yet we do not wish to provide details at this moment') && (
                      `${companyData?.data?.data?.['10.2']} ${companyData?.data?.data?.['10.2.a.37'] ?? ""}`
                    )}
                    {(companyData?.data?.data?.['10.2'] == 'No – We believe there is no need to set such requirements or practices') && (
                      `${companyData?.data?.data?.['10.2']} ${companyData?.data?.data?.['10.2.a.37'] ?? ""}`
                    )}
                  </span>
                  <span className='company-policy-item-ans-section-mobile'>{(companyData?.data?.data?.['10.2.b'])}</span><br/>
                  <br/><br/>
                </div>
                <div className='company-policy-item-sub-text-mobile'>
                  10.3 Adopt any measures to ensure a gender-inclusive process during recruitment and promotion process<br/><br/>
                  <span style={{marginLeft:"16px",fontWeight:"600"}}><br/>{companyData?.data?.data?.['10.3']}</span>

                  {/* <span style={{fontWeight:"600"}}>
                    {companyData?.data?.data?.['10.3'] == "Yes" && 
                      companyData?.data?.data?.['10.3.a'].map((item) => (
                        <span>{item},</span>
                      ))
                    }
                  </span> */}
                  <span style={{marginLeft:"16px",fontWeight:"600"}}><br/>{companyData?.data?.data?.['10.3.a']}</span>
                  <br/><br/>
                </div>
              </>
            :
              <>
                <div className='company-policy-item-sub-text-mobile'>
                  10.1 Tracks the proportion of women in total new hire and/or promotion <br/><br/>
                  <span className='company-policy-item-ans-section-mobile'>{companyData?.data?.data?.['10.1']}</span><br/>
                  <br/><br/>
                </div>
                <div className='company-policy-item-sub-text-mobile'>
                  10.2 Tracks the proportion of women in total new hire and/or promotion, categorized by level<br/><br/>
                  <span className='company-policy-item-ans-section-mobile'>{companyData?.data?.data?.['10.2']}</span><br/>
                  <br/><br/>
                </div>
                <div className='company-policy-item-sub-text-mobile'>
                  10.3 Has measures to ensure a gender-inclusive process during recruitment and promotion <br/><br/>
                  {companyData?.data?.data?.['10.3'][0] == 'N' && companyData?.data?.data?.['10.3'][1] == 'o' ? (
                    <span style={{fontWeight:"600"}}>{companyData?.data?.data?.['10.3']}</span>
                  ) : (
                    <span style={{fontWeight:"600"}}>
                      <span>{companyData?.data?.data?.['10.3']}<br/></span>
                      {companyData?.data?.data?.['10.3'] == "Yes" && 
                        companyData?.data?.data?.['10.3.a'].map((item) => (
                          <span className='company-policy-item-ans-line'>{item}</span>
                        ))
                      }
                      {companyData?.data?.data?.['10.3.a.i']}
                    </span>
                  )}
                  <br/><br/>
                </div>
              </>
            }
          </div>

          <div className='company-policy-section'>
            <div className='company-policy-items-mobile'>
              <div className='company-policy-item-text-mobile' onClick={(event) => toggleSubText(event)}>+ Retention</div>
            </div>
            {gold ?
              <>
                <div className='company-policy-item-sub-text-mobile'>
                  11.1 Tracks the proportion of women in employees who left the entity comprised of women, categorised by level <br/><br/>
                  <span className='company-policy-item-ans-section-mobile'>
                    {companyData?.data?.data?.['11.1']}<br/>
                    {(companyData?.data?.data?.['11.1'] == 'Yes – We track such information') && 
                      <span>
                        - Total workforce: xx% of employees who left are female <br/>
                        - Senior management (including C-suite, excluding Board members): xx% of employees who left are female <br/>
                        - Middle and other management: xx% of employees who left are female <br/>
                        - Non-managerial staff: xx% of employees who left are female" <br/>
                      </span>
                    }
                  </span>
                  <br/><br/>
                </div>

                <div className='company-policy-item-sub-text-mobile'>
                  11.2 Holds senior management accountable for gender diversity practices and performance <br/><br/>
                  <span style={{ marginLeft: "16px", fontWeight: "600", marginTop: "60px" }}>
                    {companyData?.data?.data?.['11.2'] === 'Yes – The entity holds its senior management accountable for its gender diversity practices and performance via the following' && (
                      <>
                        {companyData?.data?.data?.['11.2']}: <br/>
                        {companyData?.data?.data?.['11.2.a'].map((item, index) => (
                          <span key={index}>- {item}<br/></span>
                        ))}
                      </>
                    )}
                  </span>
                  <br/><br/>
                </div>
              </>
            :
              <div className='company-policy-item-sub-text-mobile'>
                11.1 Tracks the proportion of women in employees who left the entity comprised of women, categorised by level <br/><br/>
                <span className='company-policy-item-ans-section-mobile'>{companyData?.data?.data?.['11.1']}</span><br/>
                <br/><br/>
              </div>
            }
          </div>

          <div className='company-policy-section'>
            <div className='company-policy-items-mobile'>
              <div className='company-policy-item-text-mobile' onClick={(event) => toggleSubText(event)}>+ Training</div>
            </div>
            <div className='company-policy-item-sub-text-mobile'>
              12.1 Provides employee training or capacity building on gender issues<br/><br/>
              <span style={{fontWeight:"600"}}>
                {companyData?.data?.data?.['12.1']} <br />
                  {companyData?.data?.data?.['12.1.a']?.map((item, index) => (
                      <React.Fragment key={index}>
                          <span style={{fontWeight:"600"}}>{item}</span>
                          <br />
                      </React.Fragment>
                  ))}
                {companyData?.data?.data?.['12.1.a.i']}
              </span>
              <br/><br/>
            </div>
            <div className='company-policy-item-sub-text-mobile'>
              12.2 Provides women-focused talent/ leadership development programme <br/><br/>
              <span className='company-policy-item-ans-section-mobile'>{companyData?.data?.data?.['12.2']}</span><br/>
              {companyData?.data?.data?.['12.2.a']?.map((item, index) => (
                  <React.Fragment key={index}>
                      <span style={{marginLeft:"16px",fontWeight:"600"}}>{item}</span>
                      <br />
                  </React.Fragment>
                ))}
              {companyData?.data?.data?.['12.2.a.i']}
              <br/><br/>
            </div>
          </div>

          <div className='company-policy-section'>
            <div className='company-policy-items-mobile'>
              <div className='company-policy-item-text-mobile' onClick={(event) => toggleSubText(event)}>+ Employee engagement</div>
            </div>
            <div className='company-policy-item-sub-text-mobile'>
              13.1 Conducts regular employee surveys to gauge satisfaction and feedback on gender-related issues <br/><br/>
              <span className='company-policy-item-ans-section-mobile'>{companyData?.data?.data?.['13.1']}</span><br/>
              {companyData?.data?.data?.['13.1.a']?.map((item, index) => (
                <React.Fragment key={index}>
                    <span style={{marginLeft:"16px",fontWeight:"600"}}>{item}</span>
                    <br />
                </React.Fragment>
              ))}
              <span style={{marginLeft:"16px",fontWeight:"600"}}>{companyData?.data?.data?.['13.1.a.i']}</span>
              <br/><br/>
            </div>
          </div>
        </div>

        <div className='company-policy-mobile'>
          <div className='company-policy-header'>
            <div className='company-policy-header-line1'>
              <div className='company-policy-title-mobile'>External Engagement</div>
              <div className='plus-sign-btn-mobile' onClick={(event) => expandOne(event)}><p className='plus-sign-mobile'>{expanded ? "-" : "+"}</p></div>
            </div>
            <div className='company-policy-text-mobile'>On various key woman topics, does the entity extend the efforts to promote the same standards further in its value chain?</div>
          </div>
          <div className='company-policy-section'>
            <div className='company-policy-items-mobile'>
              <div className='company-policy-item-text-mobile' onClick={(event) => toggleSubText(event)}>+ Supply chain practices</div>
              {/* <div className='company-policy-item-star'>★ Star Practice</div> */}
            </div>
            <div className='company-policy-item-sub-text-mobile'>
              14.1 Has any supply chain or procurement policy with gender as one of its considerations<br/><br/>
              <span className='company-policy-item-ans-section-mobile'>{companyData?.data?.data?.['14.1']}</span><br/>
              {companyData?.data?.data?.['14.1.a']?.map((item, index) => (
                  <React.Fragment key={index}>
                    <span style={{ marginLeft: "16px", fontWeight: "600" }}>{item}</span>
                    <br />
                  </React.Fragment>
                ))}
                <span style={{ marginLeft: "16px", fontWeight: "600" }}>{companyData?.data?.data?.['14.1.a.1']}</span>
              <br/><br/>
            </div>
          </div>

          <div className='company-policy-section'>
            <div className='company-policy-items-mobile'>
              <div className='company-policy-item-text-mobile' onClick={(event) => toggleSubText(event)}>+ Community engagement</div>
            </div>
            {gold ? 
              <div className='company-policy-item-sub-text-mobile'>
                15.1 Has any policy that avoids gender stereotyping through marketing practices<br/><br/>
                <span className='company-policy-item-ans-section-mobile'>
                  {(companyData?.data?.data?.['15.1']) == "Yes" ? "Yes." : ""} {companyData?.data?.data?.['15.1.a']}
                </span>
                <br/><br/>
              </div>
            :
              <div className='company-policy-item-sub-text-mobile'>
                15.1 Conducts and/or sponsors any programmes for women in the community<br/><br/>
                <span className='company-policy-item-ans-section-mobile'>{companyData?.data?.data?.['15.1']}</span><br/>
                <span style={{fontWeight:"600"}}>{companyData?.data?.data?.['15.1.a']}</span>
                <br/><span style={{marginLeft:"16px",fontWeight:"600"}}>{companyData?.data?.data?.['15.1.a.1']}</span>
                <br/><br/>
              </div>
            }

            {gold &&
              <div className='company-policy-item-sub-text-mobile'>
                16.1 Conducts and/or sponsors any programmes for women in the community<br/><br/>
                <span className='company-policy-item-ans-section-mobile'>
                  {(companyData?.data?.data?.['16.1'])}&nbsp;
                  {companyData?.data?.data?.['16.1.a']?.map((item, index) => (
                    <span key={index}>{item}. </span>
                  ))}
                  <br/>
                  {(companyData?.data?.data?.['16.1.a.1'])}
                </span>
                <br/><br/>
              </div>
            }

            {gold &&
              <div className='company-policy-item-sub-text-mobile'>
                16.2 External recognition for its gender diversity practices or participate in any global/regional gender-related indexes <br/><br/>
                <span className='company-policy-item-ans-section-mobile'>
                  {(companyData?.data?.data?.['16.2'])}.&nbsp;
                  {companyData?.data?.data?.['16.2.a']?.map((item, index) => (
                    <span key={index}>{item}. </span>
                  ))}
                  <br/>
                  {(companyData?.data?.data?.['16.2.a.1'])}
                </span>
                <br/><br/>
              </div>
            }
          </div>
        </div>

        <div className='company-policy-mobile'>
          <div className='company-policy-header'>
            <div className='company-policy-header-line1'>
              <div className='company-policy-title-mobile'>Further Information</div>
              <div className='plus-sign-btn-mobile' onClick={(event) => expandOne(event)}><p className='plus-sign-mobile'>{expanded ? "-" : "+"}</p></div>
            </div>
            <div className='company-policy-text-mobile'></div>
          </div>
          <div className='company-policy-section'>
            <div className='company-policy-items-mobile'>
              <div className='company-policy-item-text-mobile' onClick={(event) => toggleSubText(event)}>+ Supplementary Information</div>
              {/* <div className='company-policy-item-star'>★ Star Practice</div> */}
            </div>
            {gold? 
                <div className='company-policy-item-sub-text'>
                  <span style={{marginLeft:"16px",fontWeight:"600",marginTop:"60px"}}>{(companyData?.data?.data?.['17.1'][0] == 'N' && companyData?.data?.data?.['17.1'][1] == 'o') ? "N/A" : companyData?.data?.data?.['17.1']}</span><br/>
                  <span style={{marginLeft:"16px",fontWeight:"600"}}>{companyData?.data?.data?.['17.1.a']}</span>
                  <br/><br/>
                </div> : 
                <div className='company-policy-item-sub-text'>
                  <span style={{marginLeft:"16px",fontWeight:"600"}}>{companyData?.data?.data?.['16.1']}</span>
                  <br/><br/>
                </div>}
          </div>
        </div>
      </div>
    </>
    )
}

export default CompanyProfileMobile;

import React, { useState, useEffect } from 'react';
import ReactDOMServer from 'react-dom/server';
import html2pdf from 'html2pdf.js/dist/html2pdf.min';
import axios from 'axios';
import "../CompanyScorecard.css";
import "../CompanyProfile.css";
import CompanyScorecardMobile from './CompanyScorecard-Mobile';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import SearchIcon from '../images/search-icon.svg'
import goldBadge from '../images/gold_badge.png'
import basicBadge from '../images/basic_badge.png'
import CompanyProfile from './CompanyProfile';
import { isMobile } from 'react-device-detect';
import LoadingSpinner from './LoadingSpinner';
import CompanyScorecardPdf from './CompanyScorecardPdf';

const CompanyScorecard = () => {
  const [showCompanyProfile, setShowCompanyProfile] = useState(false);
  const [companyId, setCompanyId] = useState(null);
  const [companyData, setCompanyData] = useState([]);
  const [companyDataDuplicate, setCompanyDataDuplicate] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const itemsPerPage = 10;
  const [sortBy, setSortBy] = useState(null);
  const [subsortBy, setSubSortBy] = useState(null);
  const [sortOrder, setSortOrder] = useState('asc');
  const [timestamp, setTimestamp] = useState(null);

  const handleSort = (columnName, subColumnName = null) => {
    setCurrentPage(1);
    if (sortBy === columnName) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSubSortBy(subColumnName);
      setSortBy(columnName);
      setSortOrder('asc');
    }
  };

  const handleTimestamp = () => {
    if (companyDataDuplicate.length > 0) {
      const maxTimestamp = Math.max(...companyDataDuplicate.map(obj => obj.timestamp));

      const timestampInMilliseconds = maxTimestamp * 1000;
      // Create a new Date object from the timestamp
      const dateObject = new Date(timestampInMilliseconds);

      // Format the date as "Month, Year" using Intl.DateTimeFormat
      const formattedDate = new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: 'long'
      }).format(dateObject);

      setTimestamp(formattedDate);
    }
  }

  useEffect(() => {
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    };

    axios.post('https://wwi-questionnaire-v2.azurewebsites.net/api/getCompanyList', config)
      .then(response => {
        // const goldMembers = response.data?.list.filter(item => item.membership === 'GOLD');
        setCompanyData(response.data?.list);
        setCompanyDataDuplicate(response.data?.list);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error making POST request:', error);
        setLoading(false);
      });
  }, []);

  const handleSearchQuery = () => {
    if (!searchQuery) {
      // If search query is empty, reset to the original data
      setCompanyData(companyDataDuplicate);
    } else {
      // Filter the data based on the search query
      const filteredData = companyDataDuplicate.filter((company) =>
        company.data['0.1']?.normalize('NFD').replace(/[\u0300-\u036f]/g, '')?.toLowerCase()?.includes(searchQuery?.toLowerCase())
      );
      setCurrentPage(1);
      setCompanyData(filteredData);
    }
  };

  useEffect(() => {
    handleTimestamp();
  }, [companyDataDuplicate])

  useEffect(() => {
    handleSearchQuery();
  }, [searchQuery]);

  const handleBackArrowClick = () => {
    setShowCompanyProfile(!showCompanyProfile)
    setCompanyId(null);
  };

  const handleCompanyClick = (companyId) => {
    setCompanyId(companyId);
    setShowCompanyProfile(!showCompanyProfile);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const sortedItems = companyData.slice().sort((a, b) => {
    if (sortBy && sortBy != 'dummy') {
      let aValue = '';
      let bValue = '';
      if (subsortBy) {
        aValue = a.data[sortBy]?.[subsortBy] || '';
        bValue = b.data[sortBy]?.[subsortBy] || '';
      }
      else {
        if (a?.data?.[sortBy]) {
          aValue = a.data[sortBy] || '';
          bValue = b.data[sortBy] || '';
        } else {
          aValue = a[sortBy] || '';
          bValue = b[sortBy] || '';
        }
      }

      return sortOrder === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
    }
    return 0;
  });

  const currentItems = sortedItems.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleDownloadClick = () => {
    const printContent = <CompanyScorecardPdf companyData={companyData} />;
    const printHtml = ReactDOMServer.renderToString(printContent);

    const container = document.createElement('div');
    container.innerHTML = printHtml;
  
    const options = {
      margin: [15, 0, 15, 0],
      filename: 'company-list',
      image: { type: 'jpeg', quality: 1 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'landscape' },
    };    
    html2pdf(container, options);
  };

  return loading ? <LoadingSpinner/> : !showCompanyProfile ? ((!isMobile ? (
      <div className="company-scorecard">
        <div className='header-image-container'>
            <div className="image">
                <p className="main-heading">
                  Company Dashboard            
                </p>
                {/* <hr/> */}
            </div>
        </div>
        <div className='company-scorecard-content'>
          <div className='certification-status-update'>
            <p className='cert-header'>
              Certification status update: <span className='cert-header-date'>{timestamp}</span>
            </p>
            <div className='cert-line'/>
            <div className='cert-text'>
              WWi assesses Asia-based corporates and other types of organisation on their public disclosure regarding their governance, polices and practices related to women.
              The WWi Database, with employer disclosure information on women workplace representation and practices of over 250 listed and multinational companies in a variety of sectors, will be publicly released on the WWi website as from May 2024.
              {/* <span className='cert-read-more'> Read more...</span> */}
            </div>
          </div>
          <div className='search-filter-download'>
            <div className='cert-search-div'>
              <div className='cert-search'>
                <input
                  type='text'
                  placeholder='      Search'
                  className='mx-2 cert-search-text h-full w-full'
                  value={searchQuery}
                  onChange={(e) => {
                    setSearchQuery(e.target.value)
                  }}
                />
                <img src={SearchIcon} alt='search' className='cert-search-icon'></img></div>
            </div>
            {/* <div className='cert-filter'><span className='span1'>___</span><span className='span2'>__</span><span className='span3'>_</span></div> */}
            <div className='cert-download' onClick={handleDownloadClick}><div className='span4'>Download</div></div>
          </div>
        </div>
        <div className='company-details-table-container'>
          <table className='company-details-table'>
            <thead>
              <tr>
                <th rowSpan={2}>Entity name</th>
                <th rowSpan={2} onClick={() => handleSort('membership')} style={{ cursor: 'pointer' }}>
                  Membership status <br/><br/>{sortBy === 'membership' ? (sortOrder === 'asc' ? '▲' : '▼') : <FontAwesomeIcon icon={faBars} />}
                </th>
                <th rowSpan={2} onClick={() => handleSort('0.3.b')} style={{ cursor: 'pointer' }}>
                  Stock code <br/><br/>{sortBy === '0.3.b' ? (sortOrder === 'asc' ? '▲' : '▼') : <FontAwesomeIcon icon={faBars} />}
                </th>
                <th colSpan={5}>Selected Transparency Indicators</th>
                <th rowSpan={2} onClick={() => handleSort('0.5', '0')} style={{ cursor: 'pointer' }}>
                  Sector <br/><br/>{sortBy === '0.5' ? (sortOrder === 'asc' ? '▲' : '▼') : <FontAwesomeIcon icon={faBars} />}
                </th>
                <th rowSpan={2} onClick={() => handleSort('0.7.a')} style={{ cursor: 'pointer' }}>
                  Entity Global HQ <br/><br/>{sortBy === '0.7.a' ? (sortOrder === 'asc' ? '▲' : '▼') : <FontAwesomeIcon icon={faBars} />}
                </th>
                <th rowSpan={2} onClick={() => handleSort('0.7.b')} style={{ cursor: 'pointer' }}>
                  Entity Asia HQ <br/><br/>{sortBy === '0.7.b' ? (sortOrder === 'asc' ? '▲' : '▼') : <FontAwesomeIcon icon={faBars} />}
                </th>
              </tr>
              <tr>
                <th onClick={() => handleSort('dummy')} style={{ cursor: 'pointer' }}>
                  Track % women in workforce by level <br/><br/>{sortBy === 'dummy' ? (sortOrder === 'asc' ? '▲' : '▼') : <FontAwesomeIcon icon={faBars} />}
                </th>
                <th onClick={() => handleSort('12.1', '0')} style={{ cursor: 'pointer' }}>
                  Provide employee training or capacity building on gender issues <br/><br/>{sortBy === '12.1' ? (sortOrder === 'asc' ? '▲' : '▼') : <FontAwesomeIcon icon={faBars} />}
                </th>
                <th onClick={() => handleSort('12.2', '0')} style={{ cursor: 'pointer' }}>
                  Provide women-focused talent/leadership program <br/><br/>{sortBy === '12.2' ? (sortOrder === 'asc' ? '▲' : '▼') : <FontAwesomeIcon icon={faBars} />}
                </th>
                <th onClick={() => handleSort('4.1', '0')} style={{ cursor: 'pointer' }}>
                  Provide parental leaves beyond statutory requirements <br/><br/>{sortBy === '4.1' ? (sortOrder === 'asc' ? '▲' : '▼') : <FontAwesomeIcon icon={faBars} />}
                </th>
                <th onClick={() => handleSort('7.1', '0')} style={{ cursor: 'pointer' }}>
                  Track any gender pay metrics <br/><br/>{sortBy === '7.1' ? (sortOrder === 'asc' ? '▲' : '▼') : <FontAwesomeIcon icon={faBars} />}
                </th>
              </tr>
            </thead>
            <tbody>
              {currentItems && currentItems.map(company => (
                <tr key={company.id}>
                  <td style={{cursor:"pointer"}} onClick={() => handleCompanyClick(company.id)}>{company.data['0.1']}</td>
                  <td>{(() => {
                    switch (company.membership) {
                      case "GOLD":
                        return <img src={goldBadge} alt="gold" className='w-[5rem] m-auto'/>
                      case "BASIC":
                        return <img src={basicBadge} alt="basic" className='w-[5rem] m-auto'/>
                      default:
                        return <div>IN-SCOPE</div>
                    }
                  })()}</td>
                  <td>{company.data['0.3.b']}</td>
                  <td className='green-tick'>✓</td>
                  {company.data['12.1']?.[0] == 'Y' ? (
                    <td className='green-tick'>✓</td>
                  ) : (
                    <td>✘</td>
                  )}
                  {company.data['12.2']?.[0] == 'Y' ? (
                    <td className='green-tick'>✓</td>
                  ) : (
                    <td>✘</td>
                  )}
                  {company.data['4.1']?.[0] == 'Y' ? (
                    <td className='green-tick'>✓</td>
                  ) : (
                    <td>✘</td>
                  )}
                  {(company.data['7.1']?.[0] == 'Y' && 
                  company.data['7.1'] != 'Yes – We have an equal pay policy in place, but we currently do not track gender pay metrics') ? (
                    <td className='green-tick'>✓</td>
                  ) : (
                    <td>✘</td>
                  )}
                  <td>{company.data['0.5']?.[0] || "N/A"}</td>
                  <td>{company.data['0.7.a'] || "N/A"}</td>
                  <td>{company.data['0.7.b'] || "N/A"}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="pagination">
            <span className='mr-2'>Page</span>
            {Array.from({ length: Math.ceil(companyData.length / itemsPerPage) }).map(
              (_, index) => (
                <span
                  key={index}
                  onClick={() => paginate(index + 1)}
                  className={currentPage === index + 1 ? "active-page mx-2" : "" + "mx-2 inactive-page"}
                >
                  {index + 1}
                </span>
              )
            )}
          </div>
        </div>
      </div>
    ) : (<CompanyScorecardMobile />)) ) : (
      <CompanyProfile companyId={companyId} handleBackArrowClick={handleBackArrowClick} />
    );
}

export default CompanyScorecard;